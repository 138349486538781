// @flow

import classNames from 'classnames';
import mergeAll from 'lodash/fp/mergeAll';
import * as React from 'react';
import ReactHtmlParser from 'react-html-parser';
import injectSheet from 'react-jss';

import * as colors from '../design/colors';
import type { Validation } from '../submission-validation/types';
import {
  PossiblyInvalidOptionGroup,
  PossiblyInvalidQuestion,
  ValidatedField,
} from '../submission-validation/ValidationErrorUI';
import { mandatoryHeadingStyle } from './mandatoryMark';

const remify = attribute => (sizeValue: number) => (styles: Object) =>
  mergeAll([
    styles,
    {
      [attribute]: `${sizeValue / 10}rem`,
      fallbacks: { [attribute]: `${sizeValue}px` },
    },
  ]);

const fontSize = remify('fontSize');
const lineHeight = remify('lineHeight');

export const QuestionHeading = injectSheet({
  mandatory: mandatoryHeadingStyle,
  small: {
    color: colors.ns.black,
    fontSize: 18,
    fontWeight: 'normal',
    marginBottom: 13,
    marginTop: 10,
  },
  questionHeading: fontSize(22)(
    lineHeight(30)({ margin: '0 0 10px 0', display: 'block' }),
  ),
  repeatedInput: {
    fontSize: 20,
    fontWeight: 'normal',
    marginBottom: 5,
    marginTop: 10,
    lineHeight: 1,
  },
})(({ classes, small, repeatedInput, mandatory, text, id }) => (
  <span
    className={classNames({
      [classes.questionHeading]: true,
      [classes.mandatory]: mandatory,
      [classes.small]: small,
      [classes.repeatedInput]: repeatedInput,
    })}
    id={id ? id : undefined}
  >
    {ReactHtmlParser(text)}
  </span>
));

export const questionIdLabel = (questionId: number | string) =>
  `question-${questionId}`;

const QuestionDescriptionField = injectSheet({
  descriptionContainer: {
    fontSize: '18px',
    '& span': {
      display: 'inline-block',
      fontSize: '18px',
      verticalAlign: 'top',
    },
    '& a': {
      padding: 1,
      borderRadius: '2px',
      textDecoration: 'underline',
      '&:hover, &:focus': {
        color: colors.ns.white,
        backgroundColor: colors.primary.footerHover,
        boxShadow: `-2px 0 0 0 ${colors.primary.footerHover}, 2px 0 0 0 ${colors.primary.footerHover}`,
      },
    },
  },
})(({ description, classes }) => (
  <div className={classes.descriptionContainer}>
    {ReactHtmlParser(description)}
  </div>
));

// $FlowIgnore Flow 0.78 mangler type React.memo
const QuestionDescription = React.memo(p => (
  <React.Fragment>
    <QuestionHeading
      id={p.id}
      text={p.text}
      mandatory={p.mandatory}
      repeatedInput={p.repeatedInput}
    />
    {p.postHeading}
    <QuestionDescriptionField description={p.description} />
  </React.Fragment>
));
QuestionDescription.displayName = 'QuestionDescription';

export const LabeledOptionElements = (p: {
  description?: ?string,
  text: string,
  mandatory: boolean,
  validation: Validation,
  children: React.Node,
  postHeading?: React.Node,
  formElementId: string,
}) => (
  <div>
    <QuestionDescription
      id={questionIdLabel(p.formElementId)}
      text={p.text}
      mandatory={p.mandatory}
      postHeading={p.postHeading}
      description={p.description}
    />
    <PossiblyInvalidOptionGroup
      validation={p.validation}
      formElementId={p.formElementId}
    >
      {p.children}
    </PossiblyInvalidOptionGroup>
  </div>
);

export const LabeledGenericQuestion = (p: {
  description?: ?string,
  text: string,
  mandatory: boolean,
  validation: Validation,
  children: React.Node,
  postHeading?: React.Node,
  formElementId: string,
}) => (
  <div>
    <QuestionDescription
      id={questionIdLabel(p.formElementId)}
      text={p.text}
      mandatory={p.mandatory}
      postHeading={p.postHeading}
      description={p.description}
    />
    <PossiblyInvalidQuestion
      validation={p.validation}
      formElementId={p.formElementId}
    >
      {p.children}
    </PossiblyInvalidQuestion>
  </div>
);

export const LabeledQuestion = (p: {
  htmlFor: string,
  text: string,
  description?: ?string,
  mandatory: boolean,
  validation: Validation,
  children: React.Node,
  postHeading?: React.Node,
  repeatedInput?: ?boolean,
}) => {
  return (
    /* eslint-disable jsx-a11y/label-has-for */
    <label htmlFor={p.htmlFor}>
      <QuestionDescription
        text={p.text}
        mandatory={p.mandatory}
        postHeading={p.postHeading}
        description={p.description}
        repeatedInput={p.repeatedInput}
      />
      <ValidatedField validation={p.validation} fieldErrorId={p.htmlFor}>
        {p.children}
      </ValidatedField>
    </label>
  );
};
