// @flow

import type { Saga } from 'redux-saga';
import { call, put, select, takeEvery } from 'redux-saga/effects';

import type { AnswerAction } from '../actions';
import getSettings from '../backend/settings';
import {
  indexOfNextPageWithVisibleQuestions,
  indexOfPreviousPageWithVisibleQuestions,
} from '../form/hiddenElements';
import type { AnswerState } from '../reducer';
import { denyUserRequestDueToValidation } from '../submission-validation/denyUserRequest';
import {
  currentPageInputsValidated,
  inputsForPageAreAllValid,
} from '../submission-validation/validateInputs';
import { history } from './history';
import { alertDataMayBeLost, removeAlertDataMayBeLost } from './redirect';
import { goToPage, pageMatcher, scrollToQuestion } from './routes';
import { redirectToUrl } from '../routing/redirect';

export function* listener(): Saga<void> {
  yield takeEvery('NEXT_PAGE_REQUESTED', nextPageRequested);
  yield takeEvery('PREVIOUS_PAGE_REQUESTED', previousPageRequested);
  yield takeEvery('GOT_FORM_INFORMATION', loadFirstPage);
  yield takeEvery('SUBMIT_FINISHED', setReceiptLocation);
  yield takeEvery('FORM_POSTPONE_FINISHED', setReceiptLocation);
  yield takeEvery('URL_CHANGED', handleUrlChange);
  yield takeEvery('SCROLL_ON_PAGE', scrollOnPage);
  yield takeEvery('REDIRECT_TO_URL', redirect);
}

function* redirect(action: AnswerAction): Saga<void> {
  if (action.type !== 'REDIRECT_TO_URL') {
    throw new Error('Unexpected action type');
  }
  yield call(redirectToUrl, action.url);
}

function* loadFirstPage(): Saga<void> {
  yield call(goToPage, 0, 'replace', 'top', true);
  yield call(
    [window, window.addEventListener],
    'beforeunload',
    alertDataMayBeLost,
  );
}

const scrollToTopEmbedded = () =>
  window.parent.postMessage('scrollToStart', '*');

const scrollToTop = () => {
  if (document.body) document.body.scrollTop = 0;
};

function* setReceiptLocation(): Saga<void> {
  yield call([history, history.push], '/receipt');
  yield call(removeAlertDataMayBeLost);
  if (getSettings().mode === 'iframeEmbedded') yield call(scrollToTopEmbedded);
  else yield call(scrollToTop);
}

function* scrollOnPage(action: AnswerAction): Saga<void> {
  if (action.type !== 'SCROLL_ON_PAGE') {
    throw new Error('Unexpected action type');
  }
  yield call(scrollToQuestion, action.questionId);
}

function* matchRoutes(
  action: AnswerAction,
  matchers: (toLocation: string, fromLocation?: string) => Saga<boolean>[],
): Saga<void> {
  if (action.type !== 'URL_CHANGED') {
    throw new Error('Unexpected action type');
  }
  const { toLocation, fromLocation } = action.payload;
  for (const matcher of matchers) {
    const matchResult = yield call(
      matcher,
      toLocation.pathname,
      fromLocation.pathname,
    );
    if (matchResult === true) {
      return;
    }
  }
}

function* handleUrlChange(action): Saga<void> {
  const comingFromReceipt = yield select(
    state => state.answer.submissionResponse != null,
  );
  if (comingFromReceipt) {
    yield call([window.location, window.location.reload]);
    return;
  }
  yield call(matchRoutes, action, [pageMatcher]);
}

function* validateAndFindNextPage(): Saga<[boolean, number]> {
  const state: AnswerState = yield select(state => state.answer);
  const { form, currentPageIndex } = state;
  if (form == null) throw new Error('form not loaded');
  if (currentPageIndex >= form.pages.length - 1)
    throw new Error('no next page exists');
  const validatedInputs = currentPageInputsValidated(
    state.currentPageIndex,
    form,
    state.inputs,
  );
  yield put({ type: 'VALIDATION_PERFORMED', payload: { validatedInputs } });
  const nextPageIndex = indexOfNextPageWithVisibleQuestions(
    currentPageIndex,
    form.pages,
    validatedInputs,
  );
  const currentPage = form.pages[state.currentPageIndex];
  const allInputsValid = inputsForPageAreAllValid(validatedInputs, currentPage);
  if (nextPageIndex <= currentPageIndex) {
    throw new Error('no next page exists with visible questions');
  }
  return [allInputsValid, nextPageIndex];
}

function* nextPageRequested(): Saga<void> {
  const [allInputsValid, nextPageIndex] = yield call(validateAndFindNextPage);
  if (allInputsValid) {
    yield call(goToPage, nextPageIndex);
  } else {
    yield call(denyUserRequestDueToValidation);
  }
}

function* findPreviousPageIndex(): Saga<number> {
  const state: AnswerState = yield select(state => state.answer);
  const { form, currentPageIndex } = state;

  if (form == null) throw new Error('form not loaded');
  const previousPageIndex = indexOfPreviousPageWithVisibleQuestions(
    currentPageIndex,
    form.pages,
    state.inputs,
  );
  if ([-1, currentPageIndex].includes(previousPageIndex)) {
    throw new Error('no previous page exists with visible questions');
  }
  return previousPageIndex;
}

function* previousPageRequested(): Saga<void> {
  const previousPageIndex = yield call(findPreviousPageIndex);
  yield call(goToPage, previousPageIndex, 'push', 'top');
}

export const forTesting = {
  previousPageRequested,
  nextPageRequested,
  findPreviousPageIndex,
  validateAndFindNextPage,
};
