// @flow

import * as React from 'react';
import { responsive } from 'react-usit-ui';

import type {
  DateFormat,
  ElementType,
  Question,
} from '../backend/legacyJsonBackend';
import type { FormInput, InputValue } from '../inputs/types';
import { create, structure } from '../inputs/types';
import { invalid } from '../submission-validation/types';
import DateAndOrTimeElement from './dateAndTime';
import Dropdown from './dropdown';
import * as combinations from './elementValueCombinations.js';
import FileUpload from './FileUpload.js';
import * as labels from './labels.js';
import LinearScaleRadio from './LinearScaleRadio';
import * as MultipleChoice from './multiple-choice';
import Slider from './Slider';
import { RepeatedTextInput, TextAreaInput, TextInput } from './textInput.js';

export const LabeledSingleQuestionElement = (p: {
  onInputValue: (value: ?InputValue) => void,
  question: Question,
  input: FormInput,
  type: ElementType,
  repeat?: ?boolean,
  dateFormat: ?DateFormat,
  postHeading?: React.Node,
  maxNumberOfCharacters?: number,
  isAnswerHashed?: ?boolean,
  linearScaleType?:
    | 'NONE'
    | 'START_END'
    | 'START_MID_END'
    | 'ALL'
    | 'MARK'
    | 'RADIO',
}) => {
  const id = `question-${p.question.questionId}`;

  const { validation, value } = p.input;

  if (['RADIO', 'CHECKBOX'].includes(p.type)) {
    return (
      <labels.LabeledOptionElements
        description={p.question.description}
        text={p.question.text}
        mandatory={p.question.mandatory}
        validation={p.input.validation}
        formElementId={p.question.questionId.toString()}
        postHeading={p.postHeading}
      >
        <SingleQuestionElement
          onInputValue={p.onInputValue}
          question={p.question}
          input={p.input}
          type={p.type}
          id={id}
        />
      </labels.LabeledOptionElements>
    );
  }
  if ('LINEAR_SCALE' === p.type) {
    const isSlider = p.linearScaleType !== 'RADIO';
    const scaleType = `${p.type}_${isSlider ? 'SLIDER' : 'RADIO'}`;
    return (
      /* TODO: fix this */
      <labels.LabeledGenericQuestion
        htmlFor={id}
        description={p.question.description}
        text={p.question.text}
        mandatory={p.question.mandatory}
        validation={p.input.validation}
        formElementId={p.question.questionId.toString()}
        postHeading={p.postHeading}
      >
        <SingleQuestionElement
          onInputValue={p.onInputValue}
          question={p.question}
          input={p.input}
          type={scaleType}
          id={id}
          repeat={p.repeat}
          linearScaleType={p.linearScaleType}
        />
      </labels.LabeledGenericQuestion>
    );
  }

  if (p.repeat && combinations.textInput(value, p.type)) {
    return (
      <RepeatedTextInput
        id={id}
        mandatory={p.question.mandatory}
        mainValue={givenOrEmptyString(value)}
        repeatedValue={repeatedOrEmptyString(value)}
        onInputValue={({ newMain, newRepeated }) => {
          p.onInputValue(
            create.repeatedText(
              newMain == null ? givenOrEmptyString(value) : newMain,
              newRepeated == null ? repeatedOrEmptyString(value) : newRepeated,
            ),
          );
        }}
        validation={validation}
        disabled={value.disabled}
        question={p.question}
        input={p.input}
        postHeading={p.postHeading}
        isAnswerHashed={p.isAnswerHashed}
        elementType={p.type}
      />
    );
  }

  return (
    <labels.LabeledQuestion
      description={p.question.description}
      text={p.question.text}
      mandatory={p.question.mandatory}
      htmlFor={id}
      validation={p.input.validation}
      postHeading={p.postHeading}
    >
      {p.type === 'DATE' ? (
        <DateAndOrTimeElement
          onInputValue={p.onInputValue}
          dateFormat={p.dateFormat}
          mandatory={p.question.mandatory}
          input={p.input}
          type={p.type}
          id={id}
        />
      ) : (
        <SingleQuestionElement
          onInputValue={p.onInputValue}
          question={p.question}
          input={p.input}
          type={p.type}
          id={id}
          repeat={p.repeat}
          maxNumberOfCharacters={p.maxNumberOfCharacters}
          isAnswerHashed={p.isAnswerHashed}
        />
      )}
    </labels.LabeledQuestion>
  );
};

const whitespaceRegexGlobal = /\s/g;
const removeWhitespaceFromString = (str: string): string =>
  str.replace(whitespaceRegexGlobal, '');

const SingleQuestionElement = responsive.HOC(responsive.media.max640)(
  (p: {
    onInputValue: (value: InputValue) => void,
    question: Question,
    input: FormInput,
    type: ElementType,
    id: string,
    repeat?: ?boolean,
    responsive: boolean,
    linearScaleType?: string,
    maxNumberOfCharacters?: number,
    isAnswerHashed?: boolean,
  }) => {
    const { validation, value } = p.input;
    if (combinations.slider(value, p.type)) {
      return (
        <Slider
          linearScaleType={p.linearScaleType}
          question={p.question}
          id={p.id}
          disabled={value.disabled}
          onInputValue={input =>
            p.onInputValue(
              input.length ? create.text(input) : create.notProvided(),
            )
          }
        />
      );
    }
    if (combinations.linearScaleRadio(value, p.type)) {
      return (
        <LinearScaleRadio
          id={p.id}
          mandatory={p.question.mandatory}
          disabled={value.disabled}
          max={p.question.maximumValue}
          min={p.question.minimumValue}
          minimumValueText={p.question.minimumValueText}
          maximumValueText={p.question.maximumValueText}
          onInputValue={input => p.onInputValue(create.text(input))}
          input={p.input}
        />
      );
    }
    if (combinations.attachment(value, p.type)) {
      return (
        <FileUpload
          id={p.id}
          mandatory={p.question.mandatory}
          selectedFileName={
            value.type === structure.file.type ? value.given.name : null
          }
          disabled={value.disabled}
          onSelectedFile={(selected: ?File) => {
            p.onInputValue(
              selected == null ? create.notProvided() : create.file(selected),
            );
          }}
          selectedFile={value.type === structure.file.type ? value.given : null}
        />
      );
    }
    if (combinations.storedAttachment(value, p.type)) {
      return (
        <FileUpload
          id={p.id}
          mandatory={p.question.mandatory}
          attachmentId={
            value.type === structure.storedFile.type
              ? value.given.answerAttachmentId
              : null
          }
          selectedFileName={
            value.type === structure.storedFile.type
              ? value.given.filename
              : null
          }
          disabled={value.disabled}
          onSelectedFile={(selected: ?File) => {
            p.onInputValue(
              selected == null ? create.notProvided() : create.file(selected),
            );
          }}
        />
      );
    }
    if (combinations.dropdown(value, p.type)) {
      return (
        <Dropdown
          questionId={p.question.questionId}
          id={p.id}
          text={p.question.text}
          answerOptions={p.question.answerOptions}
          mandatory={p.question.mandatory}
          invalid={invalid(validation)}
          onSelectedOption={optionId => {
            p.onInputValue(create.singleOption(optionId));
          }}
          selectedOptionId={
            value.type === structure.singleOption.type ? value.given : null
          }
          disabled={value.disabled}
        />
      );
    }
    if (combinations.radio(value, p.type)) {
      return (
        <MultipleChoice.RadioButtons
          questionId={p.question.questionId}
          answerOptions={p.question.answerOptions}
          labelledBy={labels.questionIdLabel(p.question.questionId)}
          mandatory={p.question.mandatory}
          horizontal={p.responsive ? false : p.question.horizontal}
          responsive={p.responsive}
          invalid={invalid(validation)}
          onInputValue={optionId => {
            p.onInputValue(
              optionId == null
                ? create.singleOption(null)
                : create.singleOption(optionId),
            );
          }}
          selectedOptionId={
            value.type === structure.singleOption.type ? value.given : null
          }
          disabled={value.disabled}
        />
      );
    }
    if (combinations.checkbox(value, p.type)) {
      return (
        <MultipleChoice.Checkboxes
          answerOptions={p.question.answerOptions}
          horizontal={p.responsive ? false : p.question.horizontal}
          responsive={p.responsive}
          labelledBy={labels.questionIdLabel(p.question.questionId)}
          invalid={invalid(validation)}
          mandatory={p.question.mandatory}
          onInputValue={optionIds => {
            p.onInputValue(create.multipleOptions(optionIds));
          }}
          selectedOptionIds={
            value.type === structure.multipleOptions.type ? value.given : null
          }
          questionId={p.question.questionId}
          disabled={value.disabled}
        />
      );
    }

    if (combinations.textInput(value, p.type)) {
      const InputComponent =
        p.type === 'QUESTION_MULTILINE' ? TextAreaInput : TextInput;
      return (
        <InputComponent
          id={p.id}
          mandatory={p.question.mandatory}
          value={givenOrEmptyString(value)}
          onInputValue={input => p.onInputValue(create.text(input))}
          mutationOnBlur={
            p.type === 'NUMBER' ? removeWhitespaceFromString : null
          }
          validation={validation}
          disabled={value.disabled}
          maxNumberOfCharacters={p.maxNumberOfCharacters}
          isAnswerHashed={p.isAnswerHashed}
          elementType={p.type}
        />
      );
    }
    throw new Error(`Unmatched combination: ${value.type}, ${p.type}`);
  },
);

const givenOrEmptyString = value =>
  value.type === structure.text.type ? value.given : '';

const repeatedOrEmptyString = value =>
  value.type === structure.text.type && value.repeated ? value.repeated : '';

export const forTesting = { SingleQuestionElement };
