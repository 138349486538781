// @flow

import { Trans } from '@lingui/macro';
import cn from 'classnames';
import * as React from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Row, responsive } from 'react-usit-ui';

import * as Backend from '../backend/legacyJsonBackend';
import getSettings from '../backend/settings';
import { HighSecurityServiceWarningContainer } from '../components/HighSecurityServiceWarning';
import { OperationMessageContainer } from '../components/OperationMessage';
import { elementShouldBeHidden } from '../form/hiddenElements';
import type { FormInput } from '../inputs/types';
import type { Mode } from '../modes';
import { ConnectedElement } from '../question-elements/FormElement';
import { mandatoryHeadingStyle } from '../question-elements/mandatoryMark';
import { ValidationErrorMessage } from '../submission-validation/ErrorBox';
import { invalidInputsFilteredForRespondent } from '../submission-validation/validateInputs';
import { nextPageExists } from './controls';
import {
  ControlButtons,
  ProgressBar,
  shouldShowProgressIndicator,
} from './controls';
import { FORM_PAGE_ID } from './formPageId';
import FormTitle from './Title';

const Page = (p: {
  elements: Backend.Element[],
  inputs: FormInput[],
  className?: string,
  pages: Backend.Page[],
}) => (
  <div id={FORM_PAGE_ID} className={p.className}>
    {p.elements &&
      p.elements.map(
        element =>
          !elementShouldBeHidden(element, p.inputs, p.pages) && (
            <ConnectedElement
              key={element.elementId}
              element={element}
              disabled={false}
            />
          ),
      )}
  </div>
);

const PageContainer = connect(
  (
    { answer: { currentPageIndex, form, inputs } },
    ownProps: { className?: string },
  ) => ({
    inputs,
    elements:
      form.pages[currentPageIndex] && form.pages[currentPageIndex].elements,
    className: ownProps.className,
    pages: form.pages,
  }),
  () => ({}),
)(Page);

const style = {
  formWrapper: {
    borderBottom: { width: 3, color: '#e3e3e3', style: 'solid' },
    paddingBottom: 50,
    marginBottom: 20,
  },
  pageWithPaddedTop: { paddingTop: 50 },
  infoMandatory: {
    marginTop: 20,
  },
  mandatory: mandatoryHeadingStyle,
  mandatoryExtra: {
    fontSize: '2.2rem',
  },
  [responsive.media.max640]: {
    formWrapper: {
      hyphens: 'auto',
    },
  },
};

type Classes = { [$Keys<typeof style>]: string };

const showHighSecurityServiceWarning =
  getSettings().formType === 'HIGH_SECURITY' &&
  !getSettings().highSecurityServiceEnabled;

const mandatoryQuestionExists = (page: Page) =>
  page.elements.some(element =>
    element.questions.some(question => question.mandatory),
  );

const MandatoryInfoText = (p: { classes: Classes, page: Page }) =>
  mandatoryQuestionExists(p.page) && (
    <div className={p.classes && p.classes.infoMandatory}>
      <Trans>Obligatoriske felter er merket med stjerne</Trans>
      <span
        className={cn(
          p.classes && p.classes.mandatory,
          p.classes && p.classes.mandatoryExtra,
        )}
      ></span>
    </div>
  );

const PureFormContainer = (p: {
  classes: Classes,
  currentPageIndex: number,
  pages: Page[],
  showValidationError?: boolean,
  noHeading?: boolean,
  onAbort?: () => void,
  abortText?: string,
  submitText?: string,
  hideErrorBox: boolean,
  invalidInputs: FormInput[],
  mode: Mode,
}) => {
  // TODO: Implement a soution for separate operation message for answer.
  // In the mean time use this boolean to show the same message when needed.
  var enableOperationMessageForAnswer = false;

  return (
    <React.Fragment>
      <div className={p.classes.formWrapper}>
        {enableOperationMessageForAnswer && <OperationMessageContainer />}
        {!p.noHeading && showHighSecurityServiceWarning && (
          <HighSecurityServiceWarningContainer />
        )}
        {!p.noHeading && <FormTitle />}
        {p.noHeading && showHighSecurityServiceWarning && (
          <HighSecurityServiceWarningContainer />
        )}
        {!p.hideErrorBox && p.invalidInputs.length > 0 && (
          <ValidationErrorMessage invalidInputs={p.invalidInputs} />
        )}
        {p.pages.length > 1 && shouldShowProgressIndicator() && (
          <ProgressBar
            progress={((p.currentPageIndex + 1) * 100) / p.pages.length}
          />
        )}
        <MandatoryInfoText
          classes={p.classes}
          page={p.pages[p.currentPageIndex]}
        />
        <PageContainer
          className={p.noHeading ? undefined : p.classes.pageWithPaddedTop}
        />
        {showHighSecurityServiceWarning && (
          <HighSecurityServiceWarningContainer showAtBottom />
        )}
      </div>
      <ControlButtons
        submitText={p.submitText}
        abortText={p.abortText}
        mode={p.mode}
        onAbort={p.onAbort}
      />
    </React.Fragment>
  );
};

export const ConnectedFormContainer = connect(
  (
    state,
    ownProps: {
      noHeading?: boolean,
      classes: Classes,
      onAbort?: () => void,
      abortText?: string,
      submitText?: string,
      mode: Mode,
    },
  ) => ({
    pages: state.answer.form == null ? [] : state.answer.form.pages,
    noHeading: ownProps.noHeading,
    classes: ownProps.classes,
    onAbort: ownProps.onAbort,
    currentPageIndex: state.answer.currentPageIndex,
    hideErrorBox: state.answer.hideErrorBox,
    mode: ownProps.mode,
    invalidInputs:
      state.answer.form == null
        ? []
        : invalidInputsFilteredForRespondent(
            state.answer.form,
            state.answer.inputs,
            state.answer.form.pages[state.answer.currentPageIndex],
            !nextPageExists(state.answer),
          ),
  }),
  () => ({}),
)(PureFormContainer);

const ConnectedFormContainerInRow = props => (
  <Row>
    <ConnectedFormContainer {...props} />
  </Row>
);

export const StyledConnectedFormContainer = injectSheet(style)(
  ConnectedFormContainerInRow,
);
